<template>
  <div class="dialog_block">
    <el-pagination
      background
      @current-change="changePage"
      @size-change="handleSizeChange"
      :current-page.sync="params.page"
      :page-size.sync="params.size"
      layout="prev, pager, next"
      :total="params.total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "comPagination",
  components: {},
  props: {
    // 初始表单数据
    params: {
      type: Object,
      default: () => {
        return {
          page: 1,
          size: 10,
          total: 0,
        };
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    changePage(val) {
      window.scrollTo(0, 450);
      this.$emit("changePage", val);
    },
    handleSizeChange(val) {
      console.log("每页数据", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog_block {
  width: 100%;
  padding: 0.2rem 0.4rem;
  text-align: right;
  box-sizing: border-box;
}
</style>
<style lang="scss"></style>
