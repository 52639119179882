import { render, staticRenderFns } from "./atte_sports.vue?vue&type=template&id=2be1f834&scoped=true&"
import script from "./atte_sports.vue?vue&type=script&lang=js&"
export * from "./atte_sports.vue?vue&type=script&lang=js&"
import style0 from "./atte_sports.vue?vue&type=style&index=0&id=2be1f834&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2be1f834",
  null
  
)

export default component.exports